import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
//import {Link} from "gatsby";
import Link from '../components/Link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/automotive.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import CustomButton from '../components/custom-button/custom-button.component.jsx'

import logo from '../images/logo.svg'
import demo from '../images/request-demo.svg'
import arrow from '../images/arrow.svg'
import idengager from '../images/Automotive-idengager.svg'
import idataworkers from '../images/Automotive-idataworkers.svg'
import iopsapps from '../images/Automotive-iopsapps.svg'
import kpi from '../images/Automotive-kpi.svg'
import confirmation from '../images/services-confirmation.svg'
import service from '../images/servicerequest.svg'
import work from '../images/confirm.svg'
import carCatalog from '../images/car-catalog.svg'
import eCommerce from '../images/e-commerce-automotive.svg'
import appointment from '../images/service-appointment.svg'
import testDrive from '../images/test-request.svg'
import supportUser from '../images/support-user.svg'
import branches from '../images/branches.svg'
import assistant from '../images/assistance.svg'
import checkStatus from '../images/repair-status.svg'
import realTime from '../images/real-time.svg'
import contracts from '../images/services-contracts.svg'
import dashboard from '../images/services-dashboard.svg'

const Automotive = ({ data }) => (
	<Fragment>
		<Helmet>
			<meta name="robots" content="noindex" />
		</Helmet>
		<Layout>
			{/* <SEO title="Automotive Specific Solutions" /> */}
			<div className="automotive-page">
				<div className="Header-parent">
					<MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
				</div>
				<div className="page-content automotive-content">
					<div className="automotive-section">
						<div className="header-content">
							<h1>Automotive</h1>
							<div className="logo-parent">
								<img src={logo} alt="logo" />
							</div>
							<div className="hint">
								<h3>
									<span>Automotive iCloud Ready </span>
									<span>
										Platform is the best way to control your industry
										easy and fast. Drive to the future, NOW.
									</span>
								</h3>
							</div>
							<div className="demo-parent">
								<span>
									<Link
										to="/pricing"
										data-track-location="automotive"
										data-track-label="Request a Demo"
									>
										<img src={demo} alt="demo" />
									</Link>
								</span>
							</div>
						</div>
					</div>
					<section className="products-content" id="products-content">
						<h1 className="product-header">Offering</h1>
						<div className="products-container">
							<div className="product-wrapper">
								<div className="product-parent">
									<div className="product-details">
										<h3 className="title">
											Boost your team productivity with OKR
											solution build for Automotive industry
										</h3>
										<p className="subtitle">
											Linking KPIs to daily and weekly OKRs is
											essential for employees to keep track of
											their targets and eventually the company's
											own vision and goal. KPI Builder not only
											documents every employee's day to day job
											but also reflects them into percentages to
											be able to keep track of where the employee
											is now at against their targets.
										</p>
										<CustomButton className="link-wrapper">
											<Link
												className="product-btn"
												data-track-location="automotive"
												to="/pricing"
											>
												Request a Demo
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img src={kpi} className="item-img" alt="kpi" />
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent reverse-parent">
									<div className="product-details">
										<h3 className="title">
											Digital engagement app socially built for
											Automotive industry
										</h3>
										<p className="subtitle">
											The mobile application is now a necessity
											to bring your business closer to your
											customers and potential buyers, where they
											can browse through your cars, inquire of
											availability, shop for spare parts
											accessories and even for cars!.
										</p>
										<CustomButton className="link-wrapper">
											<Link
												className="product-btn"
												data-track-location="automotive"
												to="/pricing"
											>
												Request a Demo
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={idengager}
											className="item-img"
											alt="Customer Engagement"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent">
									<div className="product-details">
										<h3 className="title">
											Actionable Insights for Automotive Industry
										</h3>
										<p className="subtitle">
											With the executives' scarce, yet valuable
											time, the need for prominent dashboards
											that encapsulated all important figures and
											puts them together in an easy to read
											format. Not to mention the numerous amount
											of agents at the ready to carry out actions
											in a blink, all automated and all accurate
											to the point!.
										</p>
										<CustomButton className="link-wrapper">
											<Link
												className="product-btn"
												data-track-location="automotive"
												to="/pricing"
											>
												Request a Demo
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={idataworkers}
											className="item-img"
											alt="hr"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent reverse-parent">
									<div className="product-details">
										<h3 className="title">
											Operations Excellence for Automotive
											Industry
										</h3>
										<p className="subtitle">
											Supercharge your dealership. increase sales
											and customer satisfaction and meet the new
											demands of the automotive industry.
											iCloud-Ready Platform for Automotive is a
											cloud-based management system that will
											help you increase deals, raise productivity
											and drive unparalleled customer
											satisfaction. Integrating sales,
											after-sales, spare parts, finance and
											administration, iCloud-Ready Platform is
											designed to help you provide an
											unforgettable, modern service.
										</p>
										<CustomButton className="link-wrapper">
											<Link
												className="product-btn"
												data-track-location="automotive"
												to="/pricing"
											>
												Request a Demo
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={iopsapps}
											className="item-img"
											alt="iopsapps"
										/>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div className="footer-section">
					<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
				</div>
			</div>
		</Layout>
	</Fragment>
)
export const pageQuery = graphql`
	query AutomotiveQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
export default Automotive
